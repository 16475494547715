import React from "react"

const searchInputContext = React.createContext("")

export const SearchInputProvider = ({ children }) => {
  const [searchInput, setSearchInput] = React.useState({
    keyword: "",
    tag: [],
  })
  return (
    <searchInputContext.Provider value={{ searchInput, setSearchInput }}>
      {children}
    </searchInputContext.Provider>
  )
}

export const useSearchInput = () => {
  const { searchInput, setSearchInput } = React.useContext(searchInputContext)
  return { searchInput, setSearchInput }
}
