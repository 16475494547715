// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-this-website-jsx": () => import("./../../../src/pages/about-this-website.jsx" /* webpackChunkName: "component---src-pages-about-this-website-jsx" */),
  "component---src-pages-apelle-jsx": () => import("./../../../src/pages/apelle.jsx" /* webpackChunkName: "component---src-pages-apelle-jsx" */),
  "component---src-pages-approach-commitment-jsx": () => import("./../../../src/pages/approach/commitment.jsx" /* webpackChunkName: "component---src-pages-approach-commitment-jsx" */),
  "component---src-pages-approach-fiber-jsx": () => import("./../../../src/pages/approach/fiber.jsx" /* webpackChunkName: "component---src-pages-approach-fiber-jsx" */),
  "component---src-pages-approach-jsx": () => import("./../../../src/pages/approach.jsx" /* webpackChunkName: "component---src-pages-approach-jsx" */),
  "component---src-pages-approach-walnut-html-jsx": () => import("./../../../src/pages/approach/walnut.html.jsx" /* webpackChunkName: "component---src-pages-approach-walnut-html-jsx" */),
  "component---src-pages-cm-youtube-jsx": () => import("./../../../src/pages/cm-youtube.jsx" /* webpackChunkName: "component---src-pages-cm-youtube-jsx" */),
  "component---src-pages-deli-jsx": () => import("./../../../src/pages/deli.jsx" /* webpackChunkName: "component---src-pages-deli-jsx" */),
  "component---src-pages-hitokuchido-jsx": () => import("./../../../src/pages/hitokuchido.jsx" /* webpackChunkName: "component---src-pages-hitokuchido-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-faq-page-jsx": () => import("./../../../src/pages/inquiry/faq-page.jsx" /* webpackChunkName: "component---src-pages-inquiry-faq-page-jsx" */),
  "component---src-pages-inquiry-jsx": () => import("./../../../src/pages/inquiry.jsx" /* webpackChunkName: "component---src-pages-inquiry-jsx" */),
  "component---src-pages-naturel-bread-jsx": () => import("./../../../src/pages/naturel-bread.jsx" /* webpackChunkName: "component---src-pages-naturel-bread-jsx" */),
  "component---src-pages-naturel-jsx": () => import("./../../../src/pages/naturel.jsx" /* webpackChunkName: "component---src-pages-naturel-jsx" */),
  "component---src-pages-omochiya-jsx": () => import("./../../../src/pages/omochiya.jsx" /* webpackChunkName: "component---src-pages-omochiya-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-product-search-jsx": () => import("./../../../src/pages/product-search.jsx" /* webpackChunkName: "component---src-pages-product-search-jsx" */),
  "component---src-pages-qa-on-raw-materials-and-additives-jsx": () => import("./../../../src/pages/qa-on-raw-materials-and-additives.jsx" /* webpackChunkName: "component---src-pages-qa-on-raw-materials-and-additives-jsx" */),
  "component---src-pages-recipe-book-jsx": () => import("./../../../src/pages/recipe-book.jsx" /* webpackChunkName: "component---src-pages-recipe-book-jsx" */),
  "component---src-pages-slowbread-jsx": () => import("./../../../src/pages/slowbread.jsx" /* webpackChunkName: "component---src-pages-slowbread-jsx" */),
  "component---src-pages-sweets-sweets-jsx": () => import("./../../../src/pages/sweets-sweets.jsx" /* webpackChunkName: "component---src-pages-sweets-sweets-jsx" */),
  "component---src-pages-thismonth-products-jsx": () => import("./../../../src/pages/thismonth-products.jsx" /* webpackChunkName: "component---src-pages-thismonth-products-jsx" */),
  "component---src-pages-variety-bread-jsx": () => import("./../../../src/pages/variety-bread.jsx" /* webpackChunkName: "component---src-pages-variety-bread-jsx" */),
  "component---src-pages-we-jsx": () => import("./../../../src/pages/we.jsx" /* webpackChunkName: "component---src-pages-we-jsx" */),
  "component---src-templates-product-template-jsx": () => import("./../../../src/templates/product-template.jsx" /* webpackChunkName: "component---src-templates-product-template-jsx" */)
}

